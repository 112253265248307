import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import Spinner from "../ui/spinner";

const Hero3Component = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(false);
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div
      id="section-feature"
      className="relative isolate overflow-hidden bg-gray-900"
    >
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div
        aria-hidden="true"
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl">
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <a href="/" className="inline-flex space-x-6">
              <span className="rounded-full px-3 py-1 text-sm font-semibold leading-6 gradient-text ring-1 ring-inset ring-indigo-500/20">
                What's new
              </span>
              <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                <span>Just shipped v2.0</span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-500"
                />
              </span>
            </a>
          </div>
          <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Watch our&nbsp;
            <span className="gradient-text">AI Agents</span>&nbsp;
            easily handle your distribution business.
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Gain complete control with a user-friendly interface that provides real-time monitoring and detailed traceability of every action performed by AI Agents managing your wholesaling business.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href="/contact"
              className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm gradient-button"
            >
              Hire an AI Workforce
            </a>
            <a
              href="/contact"
              className="text-sm font-semibold leading-6 text-white"
            >
              Request Demo <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="mx-auto flex max-w-2xl lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32 h-full">
          <div className="hidden max-w-3xl flex-none sm:max-w-5xl lg:max-w-none my-auto md:block">
            <img
              alt="App screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/product.png"
              className="w-[50rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            />
          </div>
          <div className="flex-none my-auto md:hidden mt-3">
            <img
              alt="App screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/product.png"
              className="w-full h-64 rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero3Component;
