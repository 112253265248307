import { useEffect, useState } from "react";
import Spinner from "../ui/spinner";

const Feature3Component = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(false);
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="overflow-hidden bg-white py-12">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 gradient-text">
                AI Employees
              </h2>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Sales
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Takes orders from email, phone, and WhatsApp, generates and sends quotes instantly, and enters purchase orders (POs) directly into your ERP systems. By running sales 24/7 smoothly and efficiently, you close more deals and grow your revenue.</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Retention
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Drives repeat business by re-engaging your existing customer base with targeted recommendations, upselling, and cross-selling. This approach ensures consistent reordering, increasing long-term revenue growth.</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Support
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Handles customer support through phone, email, and WhatsApp. Provides real-time order updates and quickly resolves customer issues, ensuring strong relationships with instant responses.</p>
            </div>
          </div>
          {/* hide in mobile */}
          <div className="mx-auto flex max-w-2xl lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none h-full hidden sm:block">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none my-auto">
              <img
                alt="App screenshot"
                src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-3.png"
                className="w-[32rem] rounded-md bg-white/5"
              />
            </div>
          </div>
          {/* hide in desktop */}
          <div className="mx-auto flex block sm:hidden">
            <div className="flex-none my-auto">
              <img
                alt="App screenshot"
                src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-3.png"
                className="w-[20rem] rounded-md bg-white/5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature3Component;
