import { useEffect, useState } from "react";
import Spinner from "../ui/spinner";

const Feature4Component = () => {
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(false);
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="overflow-hidden bg-white py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 gradient-text">
                AI Employees
              </h2>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Sales
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Takes orders from email, phone, and WhatsApp, generates and sends quotes instantly, and enters purchase orders (POs) directly into your ERP systems. By running sales 24/7 smoothly and efficiently, you close more deals and grow your revenue.</p>
              <p className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Retention
              </p>
              <p className="mt-2 text-lg leading-8 text-gray-600">Drives repeat business by re-engaging your existing customer base with targeted recommendations, upselling, and cross-selling. This approach ensures consistent reordering, increasing long-term revenue growth.</p>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                AI Support
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">Handles customer support through phone, email, and WhatsApp. Provides real-time order updates and quickly resolves customer issues, ensuring strong relationships with instant responses.</p>
            </div>
          </div>
          {/* hide in mobile */}
          <div className="flex items-start justify-end lg:order-first hidden sm:block">
            <img
              alt="Product screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-3.png"
              className="w-[30rem] max-w-none rounded-xl sm:w-[35rem]"
            />
          </div>
          {/* hide in desktop */}
          <div className="flex block sm:hidden">
            <img
              alt="Product screenshot"
              src="https://temporal-seels.s3.eu-west-2.amazonaws.com/landing/Section-3.png"
              className="w-[20rem] rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature4Component;
